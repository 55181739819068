<template>
  <div>
    <el-row :gutter="6">
      <el-col :span="6">
        <el-input
          placeholder="开发者账号"
          v-model="searchData.account"
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          clearable
          filterable
          v-model="searchData.reviewStatus"
          placeholder="审核状态"
        >
          <el-option
            v-for="item in commentStatus"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch()">
          查询
        </el-button>
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template slot-scope="{ row }" slot="reviewStatus">
        <el-tag
          size="small"
          class="cursor"
          :type="row.reviewStatus | tagType(commentStatus)"
          v-if="!row.remark || row.remark == ''"
        >
          {{ row.reviewStatus | typeDesc(commentStatus) }}
        </el-tag>
        <el-popover trigger="click" v-else>
          <p
            style="
              max-width: 150px;
              word-wrap: break-word;
              word-break: break-all;
            "
          >
            审核意见：{{ row.remark }}
          </p>
          <el-tag
            size="small"
            class="cursor"
            slot="reference"
            :type="row.reviewStatus | tagType(commentStatus)"
          >
            {{ row.reviewStatus | typeDesc(commentStatus) }}
          </el-tag>
        </el-popover>
      </template>
      <template slot-scope="{ row }" slot="operateType">
        {{ row.operateType | typeDesc(operateTypeList) }}
      </template>
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="{ row }">
          <!-- <el-button type="text" @click="detailDialog(row)" v-has="'lookinfo'">
            详情
          </el-button> -->
          <el-button
            v-has="'review'"
            type="text"
            @click="checkDialog(row)"
            :disabled="[101, 102].indexOf(row.reviewStatus) >= 0"
          >
            审核
          </el-button>
        </template>
      </el-table-column>
    </PageTable>
    <el-dialog
      :close-on-click-modal="false"
      :title="checkData.operateType == 3 ? '账号注销审核' : '开发者资料详情'"
      :visible.sync="dialogVisible"
    >
      <CheckDialog
        :checkData="checkData"
        @submit="handleSubmitAudit"
        :detailData="detailData"
        :imgList="imgList"
        :financeInfoDetail="financeInfoDetail"
      ></CheckDialog>
      <!-- <CheckDialog
        :checkData="checkData"
        @submit="handleSubmitAudit"
        :detailData="detailData" :imgList="imgList"
      ></CheckDialog> -->
    </el-dialog>
    <el-dialog
      title="开发者资料详情"
      :visible.sync="EditDetailVisible"
      :close-on-click-modal="false"
    >
      <!-- <CheckDialog
        :checkData="checkData"
        @submit="handleSubmitAudit"
        :detailData="detailData" :imgList="imgList"
        :financeInfoDetail="financeInfoDetail"
      ></CheckDialog> -->
      <!-- <EditDetail :detailData="detailData" :imgList="imgList" :financeInfoDetail="financeInfoDetail"></EditDetail> -->
    </el-dialog>
  </div>
</template>

<script>
import {
  getVerifiedCheckList,
  updateStatusById,
  getDetailsByIdDiff,
  getCheckDetail,
  updateBusinessByUserId,
} from "./indexServe.js";
import { getInfoDetailDiff } from "../financeInfoCheck/indexServe";
import CheckDialog from "./checkDialog.vue";
import EditDetail from "../developerList/Detail.vue";
import { commentStatus } from "@/common/constant/constant.js";
import PageTable from "@/components/PageTableSort";
export default {
  components: { CheckDialog, EditDetail, PageTable },
  data() {
    return {
      commentStatus,
      searchData: {
        account: "",
        reviewStatus: "",
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          key: "userId",
          label: "投放者ID",
        },
        {
          key: "companyName",
          label: "投放者名称",
        },
        {
          key: "businessName",
          label: "对接商务",
        },
        // {
        //   key: "account",
        //   label: "登录账号",
        // },
        {
          key: "createTime",
          label: "提交时间",
        },
        {
          key: "modifyTime",
          label: "审核时间",
        },
        {
          slot: "operateType",
          label: "操作类型",
        },
        {
          slot: "reviewStatus",
          label: "状态",
        },
      ],
      checkData: "",
      detailData: "",
      imgList: [],
      dialogVisible: false,
      EditDetailVisible: false,
      operateTypeList: [
        { key: 1, value: "新增" },
        { key: 2, value: "修改" },
        { key: 3, value: "注销" },
      ],
      financeInfoDetail: { invoice: {}, postInfoVo: {} },
    };
  },
  methods: {
    async getInfoDetailDiff(params) {
      const res = await getInfoDetailDiff(params);
      if (res && res.code === 0) {
        this.financeInfoDetail = res.data;
      }
    },
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    async getTableList(params) {
      const data = {
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      const res = await getVerifiedCheckList(data);
      if (res && res.code == 0) {
        this.tableData = res.data;
      }
    },
    async detailDialog(row) {
      let obj = {
        id: row.identityId,
        userId: row.userId,
      };
      let res = await getDetailsByIdDiff(obj);
      const imgList = [];
      if (res.code == 0) {
        this.detailData = res.data;
        if (res.data.identityAdjunctVo) {
          for (let key in res.data.identityAdjunctVo) {
            imgList.push(res.data.identityAdjunctVo[key]);
          }
        }
        this.imgList = imgList;
        this.EditDetailVisible = true;
      }
    },
    async checkDialog(row) {
      let res = await getCheckDetail({ userId: row.userId });
      //  console.log(res);
      const imgList = [];
      if (res.code == 0) {
        this.detailData = res.data.developerUserIdentityVo || {};
        this.financeInfoDetail = res.data.paidLightVo || {};
        if (
          res.data.developerUserIdentityVo &&
          res.data.developerUserIdentityVo.identityAdjunctVo
        ) {
          for (let key in res.data.identityAdjunctVo) {
            imgList.push(res.data.identityAdjunctVo[key]);
          }
        }
        this.imgList = imgList;
        this.dialogVisible = true;
      }
      //  await this.detailDialog(row)
      //  await this.getInfoDetailDiff({ id: row.id })
      this.checkData = { ...row };
      //   this.dialogVisible = true;
    },
    async handleSubmitAudit(formData) {
      const { userId, id } = this.checkData;
      const data = {
        id,
        userId,
        ...formData,
      };
      // updateStatusById(data).then((res) => {
      updateBusinessByUserId(data).then((res) => {
        if (res && res.code === 0) {
          this.dialogVisible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
  },
  created() {
    this.getTableList();
  },
};
</script>

<style lang="scss" scoped></style>
